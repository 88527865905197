import en from "../locales/en"
import ar from "../locales/ar"
import tr from "../locales/tr"
// import useRouterStore from "stores/routerStore"
import { useRouter } from "next/router"

export default function useT(localeProp?: string) {
  // const locale = useRouterStore((state) => state.router?.locale)
  const router = useRouter()
  const computedLocal = localeProp || router.locale
  const t = computedLocal === "en" ? en : computedLocal === "ar" ? ar : tr
  return t
}
export function serverT(localeProp?: string) {
  const computedLocal = localeProp
  const t = computedLocal === "tr-TR" ? tr : computedLocal === "ar" ? ar : en
  return t
}
