import { NextRouter } from "next/dist/shared/lib/router/router"
import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"
import useRouterStore from "stores/routerStore"
import { Url } from "url"

const RouterSetter = () => {
  const router = useRouter()
  const setRouter = useRouterStore((state) => state.setRouter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const back = useCallback(() => router.back(), [])
  const push = useCallback(
    (url: Url, as: Url, options: object) => router.push(url, as, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as unknown as NextRouter["push"]
  const replace = useCallback(
    (url: Url, as: Url, options: object) => router.replace(url, as, options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  ) as unknown as NextRouter["replace"]

  useEffect(() => {
    if (!router.isReady) return
    setRouter({
      ...router,
      back,
      push,
      replace,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, router.isReady, router.query])
  return null
}

export default RouterSetter
