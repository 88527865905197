const shimmer = (isDark?: boolean, w = 359, h = 354, noShime = false) => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="${
          isDark ? "rgb(140 140 140)" : "rgb(205 205 205)"
        }" offset="20%" />
        <stop stop-color="${isDark ? "#a1a1a1" : "#e0e0e0"}" offset="50%" />
        <stop stop-color="${
          isDark ? "rgb(140 140 140)" : "rgb(205 205 205)"
        }" offset="70%" />
      </linearGradient>
    </defs>

    <rect width="${w}" height="${h}" fill="${
  isDark ? "rgb(140 140 140)" : "rgb(205 205 205)"
}" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" transform="rotate(45) translate(0 -220) scale(1.25)"/>
    <animate xlink:href="${
      noShime ? "" : "#r"
    }" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    <path stroke="black"
    d="M357.435 1.19178L6.43469 353.192M357.435 352.486L6.43469 0.485657M6 23.5H357M6 110.5H357M5 176.5H356M5 242.5H356M0 329.5H351M29.5 0.999981V348M116.5 0.999981V348M182.5 0.999981V348M248.5 0.999981V348M335.5 0.999981V348M248 177C248 213.451 218.451 243 182 243C145.549 243 116 213.451 116 177C116 140.549 145.549 111 182 111C218.451 111 248 140.549 248 177ZM278 177C278 230.019 235.019 273 182 273C128.981 273 86 230.019 86 177C86 123.981 128.981 81 182 81C235.019 81 278 123.981 278 177ZM335 177C335 261.5 266.5 330 182 330C97.5004 330 29 261.5 29 177C29 92.5004 97.5004 24 182 24C266.5 24 335 92.5004 335 177Z" fill="none" style="background-color: rgb(140 140 140);" />
  </svg>`

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str)

export default function imagePlaceholder(
  isDark?: boolean,
  w = 359,
  h = 354,
  noShime?: boolean,
) {
  return `data:image/svg+xml;base64,${toBase64(shimmer(isDark, w, h, noShime))}`
}
