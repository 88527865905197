import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import useGlobalStore from "stores/global"
import { event } from "utils/gtag"
import useMediaQuery from "utils/useMediaQuery"
import useT from "utils/useT"
import AppollowSquareLogo from "./AppollowSquareLogo"
import Modal from "./modal"
import Spinner from "./Spinner"

const InstallAppModals = () => {
  const t = useT()
  const videoRef = useRef<HTMLVideoElement>(null)
  const appVisits = useGlobalStore((state) => state.appVisits)
  const isDesktop = useMediaQuery("(min-width: 1200px)")

  const [isLoading, setLoading] = useState(false)
  const [showInstallAppSheet, setInstallAppSheet] = useState(false)
  const [showDemoSheet, setDemoSheet] = useState(false)

  useEffect(() => {
    const navigator: any = window?.navigator
    const timer = setTimeout(() => {
      if (appVisits < 5 && !isDesktop && !navigator?.standalone) {
        setInstallAppSheet(true)
        event({
          action: "install_pwa_sheet_open",
          category: "Install PWA Sheet",
          label: "install PWA sheet opened",
        })
      }
    }, 30000)
    return () => clearTimeout(timer)
  }, [appVisits, isDesktop])

  useEffect(() => {
    const navigator: any = window?.navigator
    if (navigator?.standalone) {
      event({
        action: "PWA_visit",
        category: "views",
        label: "app opened in standalone PWA",
      })
    }
  }, [])

  return (
    <div>
      <Modal
        modalOpen={showInstallAppSheet}
        close={() => {
          setInstallAppSheet(false)
          event({
            action: "install_pwa_sheet_swipe_close",
            category: "Install PWA Sheet",
            label: "install PWA sheet swipe close",
          })
        }}
      >
        <div>
          <div className="boxed-text-xl">
            <AppollowSquareLogo />
            <h4 dir="auto" className="text-center mt-4 mb-2">
              {t.pwa.addToHomeScreen}
            </h4>
            <p dir="auto" className="text-center ms-3 me-3">
              {t.pwa.addToHomeScreenDescription}
            </p>
            <button
              onClick={() => {
                setInstallAppSheet(false)
                setDemoSheet(true)
                event({
                  action: "install_pwa_sheet_click",
                  category: "Install PWA Sheet",
                  label: "install PWA sheet clicked",
                })
              }}
              className="mx-auto bg-primary btn rounded mt-4 mb-3 px-4"
            >
              {t.pwa.install}
            </button>
            <button
              onClick={() => {
                setInstallAppSheet(false)
                event({
                  action: "install_pwa_sheet_later",
                  category: "Install PWA Sheet",
                  label: "install PWA sheet later",
                })
              }}
              className="w-100 my-3 text-center text-uppercase font-900 color-red-light opacity-90 font-14"
            >
              {t.pwa.later}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        modalOpen={showDemoSheet}
        close={() => {
          setDemoSheet(false)
          event({
            action: "install_pwa_demo_sheet_swipe_close",
            category: "Install PWA Sheet",
            label: "install PWA sheet demo swipe close",
          })
        }}
      >
        <div>
          <div className="boxed-text-xl relative">
            <video
              ref={videoRef}
              src="/files/install-appollow.mov"
              width="100%"
              className="rounded-m mt-4"
              autoPlay
              onEnded={() => {
                videoRef.current?.play()
                event({
                  action: "demo_video_finish",
                  category: "Install PWA Sheet",
                  label: "install PWA sheet demo video watched fully",
                })
              }}
              playsInline
              onLoadStart={() => setLoading(true)}
              onCanPlay={() => setLoading(false)}
            ></video>
            {isLoading && (
              <div style={{ position: "absolute", top: "30%", left: "40%" }}>
                <Spinner />
              </div>
            )}
            <button
              onClick={() => {
                setDemoSheet(false)
                event({
                  action: "install_pwa_sheet_done",
                  category: "Install PWA Sheet",
                  label: "install PWA sheet done",
                })
              }}
              className="mx-auto bg-primary btn rounded mt-4 mb-3 px-4 d-block"
            >
              {t.pwa.done}
            </button>
            <Link
              onClick={() => {
                setDemoSheet(false)
                event({
                  action: "install_pwa_sheet_more_info",
                  category: "Install PWA Sheet",
                  label: "install PWA sheet more info",
                })
              }}
              href="/articles/add-appollow-to-your-home-screen"
              className="mb-3 w-100 mb-3 text-center text-uppercase font-900 color-red-light opacity-90 font-14 d-inline-block"
            >
              {t.pwa.moreInfo}
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InstallAppModals
