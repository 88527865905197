import Portal from "components/Portal"
import { AnimatePresence, motion } from "framer-motion"
import useGlobalStore from "stores/global"
import Backdrop from "../backdrop"
import DragClose from "../DragClose"

interface Props {
  children: JSX.Element | JSX.Element[]
  close: () => void
  modalOpen: boolean
  isCenter?: boolean
}

const Modal = ({ children, modalOpen, close, isCenter }: Props) => {
  const transparency = useGlobalStore((state) => state.transparency)
  const animationToggle = useGlobalStore((state) => state.animationToggle)

  return (
    <Portal>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered

        mode="wait" // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalOpen && (
          <Backdrop
            className={`backdrop ${isCenter ? "align-items-center" : ""}`}
            onClick={close}
          >
            <DragClose
              callbackFn={close}
              dragAxis="y"
              dragConstraints={{ right: 0, left: 0 }}
              dragElastic={{ top: 0, bottom: 0 }}
            >
              <motion.div
                onClick={(e) => e.stopPropagation()}
                style={{ maxWidth: 400 }}
                className={`action-sheet ${transparency ? "transparency" : ""}`}
                initial={animationToggle && { y: "30%", opacity: 1 }}
                animate={animationToggle && { y: [700, -20, 0], opacity: 1 }}
                exit={
                  animationToggle
                    ? {
                        y: [null, 300, 0],
                        opacity: 0,
                        transition: { duration: 0.25 },
                      }
                    : undefined
                }
                transition={
                  animationToggle
                    ? {
                        duration: 0.45,
                        type: "keyframes",
                        ease: "easeOut",
                      }
                    : undefined
                }
              >
                {children}
              </motion.div>
            </DragClose>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default Modal
