export const GA_TRACKING_ID = "G-LX553C63MR"
import { getSessionState } from "stores/session"
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (getSessionState().user.isAdmin || process.env.NODE_ENV == "development")
    return
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value = "" }) => {
  if (getSessionState().user.isAdmin || process.env.NODE_ENV == "development")
    return
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
