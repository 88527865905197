import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import ProgressSpinner from "./Spinner"

const Spinner = () => {
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const handleStart = () => {
      setLoading(true)
    }
    const handleStop = () => {
      setLoading(false)
    }
    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
    }
  }, [router])

  if (loading)
    return (
      <div
        className="position-fixed z-999 route-spinner"
        style={{ left: "calc(50% - 42.5px)", top: "calc(50% - 42.5px)" }}
      >
        <ProgressSpinner />
      </div>
    )
  return null
}

export default Spinner
