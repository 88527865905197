import { motion, Point } from "framer-motion"

type Props = {
  children: React.ReactNode
  dragAxis?: "x" | "y"
  callbackFn?: () => void
  dragElastic?: {
    left?: number
    right?: number
    top?: number
    bottom?: number
  }
  dragConstraints?: {
    left?: number
    right?: number
    top?: number
    bottom?: number
  }
}

const DragClose = ({
  children,
  dragAxis,
  callbackFn,
  dragElastic,
  dragConstraints,
}: Props) => {
  const swipeConfidenceThreshold = 10000
  const swipePower = (offset: Point, velocity: Point) => {
    if (dragAxis === "y") {
      return Math.abs(offset.y) * velocity.y
    }
    if (dragAxis === "x") {
      return Math.abs(offset.x) * velocity.x
    }
    return Math.abs(offset.x) * velocity.x
  }
  const dragConstraintsProp = dragConstraints
    ? dragConstraints
    : dragAxis === "x"
    ? { left: 0, right: 0 }
    : { top: 0, bottom: 0 }
  return (
    <motion.div
      className={dragAxis === "x" ? "ios-no-scroll" : ""}
      style={{
        width: dragAxis === "y" ? "95%" : "",
      }}
      drag={dragAxis ?? "x"}
      dragConstraints={dragConstraintsProp}
      dragSnapToOrigin
      dragMomentum={false}
      dragTransition={{ bounceStiffness: 1000, bounceDamping: 90 }}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset, velocity)
        if (swipe > swipeConfidenceThreshold) {
          callbackFn?.()
        }
      }}
      dragElastic={
        dragElastic ?? {
          left: 0,
          right: 0.5,
        }
      }
    >
      {children}
    </motion.div>
  )
}

export default DragClose
