import Head from "next/head"
import { useEffect, useState } from "react"

const ThemeColor = () => {
  const [color, setColor] = useState("#fff")

  useEffect(() => {
    if (document.querySelector("body")?.classList[0] === "theme-light")
      setColor("#528FDD")
    if (document.querySelector("body")?.classList[0] === "theme-dark")
      setColor("#29476E")
    if (window.matchMedia("(prefers-color-scheme: dark)").matches)
      setColor("#29476E")
    if (window.matchMedia("(prefers-color-scheme: light)").matches)
      setColor("#528FDD")
  }, [])

  return (
    <Head>
      <meta name="theme-color" content={color} />
    </Head>
  )
}

export default ThemeColor
