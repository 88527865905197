import Link from "next/link"
import { memo } from "react"
import useGlobalStore from "../stores/global"
import useT from "../utils/useT"
import AppollowLogo from "./AppollowLogo"
import AppollowSocialMediaLinks from "./AppollowSocialMediaLinks"
import NoSsr from "./NoSsr"

const Footer = () => {
  const t = useT()
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <div className="mt-auto">
      <div className="footer">
        <div className="footer card card-style mx-0 mb-0">
          <a className="footer-title pt-4">
            <NoSsr>
              <AppollowLogo fill={isDark ? "#fff" : "#000"} width="250px" />
            </NoSsr>
          </a>
          <p className="text-center font-12 mt-1 mb-3 opacity-70">
            {t.footer.p}
          </p>
          <p className="boxed-text-l">{t.footer.bestPlatform}</p>
          <AppollowSocialMediaLinks isFooter />

          <p className="footer-copyright mb-2">{t.footer.copyright}</p>
          <div className="d-flex gap-3 justify-content-center">
            <Link className="font-11" href="/terms-of-service">
              Terms of Service
            </Link>
            <Link className="font-11" href="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className="font-11" href="/about-us">
              About us
            </Link>
          </div>
        </div>
        <div
          className="footer-card card shape-rounded"
          style={{ height: "260px" }}
        >
          <div className="card-overlay bg-highlight opacity-90"></div>
        </div>
      </div>
    </div>
  )
}

export default memo(Footer)
