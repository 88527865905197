export default function openShareSheet() {
  if (navigator.share) {
    navigator
      .share({
        title: "Share Appollow app link",
        url: window?.location?.href || "https://www.appollow.net",
      })
      .then(() => {
        console.log("Thanks for sharing!")
      })
      .catch(console.error)
  } else {
    console.log("WebShare API not supported.")
  }
}
