import useMediaQuery from "utils/useMediaQuery"
import { AnimatePresence, motion } from "framer-motion"
import useUnsavedStore from "stores/unsaved"
import useGlobalStore from "stores/global"
import useRouterStore from "stores/routerStore"

const MenuIcon = () => {
  const toggleSidebar = useUnsavedStore((state) => state.toggleSidebar)
  const asPath = useRouterStore((state) => state.router?.asPath)
  const back = useRouterStore((state) => state.router?.back)
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const isMobile = useMediaQuery("(max-width: 1200px)")
  const showBars =
    asPath === "/" || asPath === "/settings" || asPath === "/submit"

  const handleClick = () => {
    if (showBars) toggleSidebar()
    else back?.()
  }

  const showDesktopButton = isMobile || (!showBars && !isMobile)

  return (
    <AnimatePresence>
      {showDesktopButton && (
        <motion.div
          initial={animationToggle && { opacity: 0, marginRight: 0 }}
          animate={
            animationToggle && {
              opacity: 1,
              width: [0, 30, 24],
              marginRight: "0.5rem",
              transition: { duration: 0.5 },
            }
          }
          exit={
            animationToggle
              ? { opacity: 0, marginRight: 0, width: 0 }
              : undefined
          }
          transition={{ duration: 0.4 }}
          onClick={handleClick}
          className={`burger-menu menu--back2 ${showBars ? "" : "open"}`}
        >
          <div className="menu__icon">
            <div className="menu__line menu__line--1"></div>
            <div className="menu__line menu__line--2"></div>
            <div className="menu__line menu__line--3"></div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default MenuIcon
