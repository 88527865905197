import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface State {
  isLoggedIn: boolean
  user: {
    role?: string
    isAdmin?: boolean
    bio?: string
    displayName: string
    email: string
    emailVerified: boolean
    phoneNumber: string
    photoURL: string
    uid: string
    accessToken: string
    providerData: any
  }
  login: (payload: any) => void
  logout: () => void
}

const useSessionStore = create<State>()(
  devtools(
    (set) => ({
      isLoggedIn: false,
      user: {
        role: "",
        displayName: "",
        email: "",
        emailVerified: false,
        phoneNumber: "",
        photoURL: "",
        uid: "",
        accessToken: "",
        providerData: undefined,
      },
      login: (payload: any) =>
        set((state) => {
          if (payload?.uid)
            return {
              ...state,
              isLoggedIn: true,
              user: {
                displayName: payload?.displayName,
                email: payload?.email,
                emailVerified: payload?.emailVerified,
                phoneNumber: payload?.phoneNumber,
                photoURL: payload?.photoURL,
                uid: payload?.uid,
                accessToken: payload?.accessToken,
                providerData: payload?.providerData,
                bio: payload?.bio,
                isAdmin: payload?.isAdmin,
              },
            }
          return {
            ...state,
            isLoggedIn: false,
            user: {
              role: "GUEST",
              displayName: "",
              email: "",
              emailVerified: false,
              phoneNumber: "",
              photoURL: "",
              uid: "",
              accessToken: "",
              providerData: undefined,
            },
          }
        }),
      logout: () =>
        set((state) => ({
          ...state,
          isLoggedIn: false,
          user: {
            role: "GUEST",
            displayName: "",
            email: "",
            emailVerified: false,
            phoneNumber: "",
            photoURL: "",
            uid: "",
            accessToken: "",
            providerData: undefined,
          },
        })),
    }),
    {
      name: "session-store",
    },
  ),
)

export const { getState: getSessionState, subscribe: subscribeSessionState } =
  useSessionStore
export default useSessionStore
