import { useStickyTop } from "utils/useStickyTop"
import useMediaQuery from "utils/useMediaQuery"
import useRouterStore from "stores/routerStore"

const HeaderCard = () => {
  const asPath = useRouterStore((state) => state.router?.asPath)
  const isSticky = useStickyTop()
  const isDesktop = useMediaQuery("(min-width: 1200px)")
  const isMobile = useMediaQuery("(max-width: 1200px)")

  return (
    <>
      <div
        className={
          !isSticky
            ? "card header-card shape-rounded position-fixed"
            : "card header-card shape-rounded"
        }
        style={{
          height: asPath !== "/" ? 150 : isDesktop ? 130 : 210,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: isMobile ? "100%" : isSticky ? "100%" : "60%",
          margin: "auto",
        }}
      >
        <div className="card-overlay bg-highlight opacity-95"></div>
        <div className="card-overlay dark-mode-tint"></div>
        <div
          className="card-bg preload-img entered loaded"
          data-ll-status="loaded"
          // style={{ backgroundImage: 'url("/images/pictures/20s.jpg")' }}
        ></div>
      </div>
    </>
  )
}

export default HeaderCard
