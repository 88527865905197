import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type Appearance = "Dark" | "Light" | "Auto"
type Language = "en" | "ar" | "tr-TR"
interface State {
  showAds: boolean
  showPages: boolean
  appearance: Appearance
  language?: Language
  translateSubmitterNote: boolean
  transparency: boolean
  animationToggle: boolean
  appVisits: number
  isDark: boolean
  search: string
  isSubscribed: boolean
  notificationPopupCount: number
  incrementNotificationPopupCount: () => void
  resetNotificationPopupCount: () => void
  setShowPages: (showPages: boolean) => void
  setShowAds: (showAds: boolean) => void
  setAppearance: (appearance: Appearance) => void
  setLanguage: (language: Language) => void
  setDark: (isDark: boolean) => void
  setTranslateSubmitterNote: (translateSubmitterNote: boolean) => void
  setAnimation: (animationToggle: boolean) => void
  setTransparency: (transparency: boolean) => void
  incrementAppVisits: () => void
  setSearch: (search: string) => void
  setNotificationsStatus: (isSubscribed: boolean) => void
}

const useGlobalStore = create<State>()(
  devtools(
    persist(
      (set) => ({
        appearance: "Auto",
        showAds: true,
        language: undefined,
        translateSubmitterNote: true,
        transparency: true,
        animationToggle: true,
        appVisits: 0,
        isDark: false,
        search: "",
        showPages: true,
        isSubscribed: false,
        notificationPopupCount: 0,
        incrementNotificationPopupCount: () =>
          set((state) => ({
            ...state,
            notificationPopupCount: (state.notificationPopupCount || 1) + 1,
          })),
        resetNotificationPopupCount: () =>
          set((state) => ({
            ...state,
            notificationPopupCount: 0,
          })),
        setNotificationsStatus: (isSubscribed: boolean) =>
          set((state) => ({ ...state, isSubscribed })),
        setShowPages: (showPages: boolean) =>
          set((state) => ({ ...state, showPages })),
        setShowAds: (showAds: boolean) =>
          set((state) => ({ ...state, showAds })),
        setAppearance: (appearance: Appearance) =>
          set((state) => ({ ...state, appearance })),
        setLanguage: (language: Language) =>
          set((state) => ({ ...state, language })),
        setDark: (isDark: boolean) => set((state) => ({ ...state, isDark })),
        setTranslateSubmitterNote: (translateSubmitterNote: boolean) =>
          set((state) => ({ ...state, translateSubmitterNote })),
        setAnimation: (animationToggle: boolean) =>
          set((state) => ({ ...state, animationToggle })),
        setTransparency: (transparency: boolean) =>
          set((state) => ({ ...state, transparency })),
        incrementAppVisits: () =>
          set((state) => ({ ...state, appVisits: state.appVisits + 1 })),
        setSearch: (search: string) => set((state) => ({ ...state, search })),
      }),
      {
        name: "global-store",
      },
    ),
  ),
)

export default useGlobalStore
