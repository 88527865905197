import openShareSheet from "utils/openShareSheet"

const AppollowSocialMediaLinks = ({ isFooter = false }) => {
  return (
    <div className="d-flex justify-content-center gap-1 mb-3">
      <a
        href="https://www.facebook.com/Appollowapp/"
        target="_blank"
        rel="noreferrer"
        className="icon icon-xs rounded-sm bg-facebook"
      >
        <i className="fab fa-facebook"></i>
      </a>
      <a
        href="https://twitter.com/Appollowapp"
        className="icon icon-xs rounded-sm bg-twitter"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-twitter"></i>
      </a>
      <a
        href="https://www.instagram.com/Appollowapp/"
        className="icon icon-xs rounded-sm bg-instagram"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-instagram"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/appollowapp/"
        className="icon icon-xs rounded-sm bg-linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
      </a>
      <a
        onClick={openShareSheet}
        className="icon icon-xs rounded-sm shadow-l bg-red-dark"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-share-alt"></i>
      </a>
      {isFooter && (
        <a
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="back-to-top icon icon-xs rounded-sm shadow-l bg-highlight color-white"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-arrow-up"></i>
        </a>
      )}
    </div>
  )
}

export default AppollowSocialMediaLinks
