import type { AppProps } from "next/app"
import { useEffect, useState } from "react"
import Layout from "../layouts/Layout"
import Header from "../components/header/Header"
import Footer from "../components/Footer"
import "../styles/globals.css"
// import "../styles/styles.css"
import "../styles/style.css"
import "../styles/bootstrap.css"
import "../styles/css/fontawesome-all.min.css"
// import "../styles/fontawesome.css"
// import Spinner from "../components/RoutSpinner"
import Script from "next/script"
import { DefaultSeo } from "next-seo"
import * as gtag from "../utils/gtag"
import AppollowImg from "../public/images/Appollow-preview.png"
import PageHead from "../components/PageHead"
import SnackbarProvider from "../components/snackbars/SnackbarProvider"
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import GoogleAnalyticsReporter from "components/GoogleAnalyticsReporter"
import Auth from "components/Auth/Auth"
import RouterSetter from "components/RouterSetter"
import Spinner from "components/RoutSpinner"
import WorkboxNotifications from "components/WorkboxNotifications"
import useT from "utils/useT"
import dynamic from "next/dynamic"
import HotJar from "components/HotJar"
import useGlobalStore from "stores/global"
import { AdUnit, GoogleAdSense } from "next-google-adsense";

const InstantAppPreview = dynamic(
  () => import("views/AppPreview/InstantAppPreview"),
)
const NotificationsModal = dynamic(
  () => import("components/NotificationsModal"),
)

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  const t = useT(router.locale)
  const [showNotificationsSheet, setShowNotificationsSheet] = useState(false)
  const notificationPopupCount = useGlobalStore(
    (state) => state.notificationPopupCount,
  )
  const incrementNotificationPopupCount = useGlobalStore(
    (state) => state.incrementNotificationPopupCount,
  )
  const resetNotificationPopupCount = useGlobalStore(
    (state) => state.resetNotificationPopupCount,
  )
  const setNotificationsStatus = useGlobalStore(
    (state) => state.setNotificationsStatus,
  )
  const isSubscribedToNotifications = useGlobalStore(
    (state) => state.isSubscribed,
  )

  useEffect(() => {
    const func = async () => {
      const navigator: any = window?.navigator

      // show the notification subscription popup only if the PWA is finished installing
      const registration: ServiceWorkerRegistration =
        await navigator.serviceWorker.getRegistration("/sw.js")
      const existingSubscription = await registration?.pushManager.getSubscription();
      // if the user has subscribed before but the subscription got deleted for some reason then show the popup again
      if (isSubscribedToNotifications && !existingSubscription) {
        setNotificationsStatus(false)
        resetNotificationPopupCount()
      }
      window?.workbox?.addEventListener("controlling", () => {
        if (
          navigator?.standalone &&
          !isSubscribedToNotifications &&
          notificationPopupCount < 7
        ) {
          setShowNotificationsSheet(true)
          incrementNotificationPopupCount()
        }
      })
      // above event only runs once so the following code will make the popup appear at least 3 times
      if (
        navigator?.standalone &&
        !isSubscribedToNotifications &&
        notificationPopupCount < 7 &&
        registration.active
      ) {
        setShowNotificationsSheet(true)
        incrementNotificationPopupCount()
      }
    }

    func().catch(console.log)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const func2 = async () => {
      const navigator: any = window?.navigator

      // Send a message to the service worker to clear the app badge when pwa is opened
      if (
        "serviceWorker" in navigator &&
        "controller" in navigator.serviceWorker &&
        "postMessage" in navigator.serviceWorker.controller
      ) {
        navigator.serviceWorker.controller.postMessage({
          command: "clearAppBadge",
        })
      }
    }

    func2().catch(console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retryDelay: (attemptIndex) =>
              Math.min(10000 * 2.5 ** attemptIndex, 200000),
          },
        },
      }),
  )

  useEffect(() => {
    if (router.query.ref) {
      gtag.event({
        action: `ref_visit_${router.query.ref}`,
        category: "ref visits",
        label: router.query.ref,
      })
    }
  }, [router.query.ref])

  return (
    <div className="page-content">
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <div id="my-portal" />
          <ReactQueryDevtools />
          <DefaultSeo
            title={t.og.title}
            canonical={"https://www.appollow.net"}
            description={t.og.description}
            facebook={{ appId: "381042603551124" }}
            openGraph={{
              type: "website",
              title: t.og.title,
              locale: router.locale,
              url: "https://www.appollow.net",
              siteName: "Appollow",
              description: t.og.description,
              images: [{ url: AppollowImg.src, alt: "Appollow apps platform" }],
            }}
            twitter={{
              site: "@Appollowapp",
              cardType: "summary_large_image",
            }}
          />
          <RouterSetter />
          <Auth />
          <Layout>
            <div>{showNotificationsSheet && <NotificationsModal />}</div>
            <HotJar />
            <PageHead />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <Script
              strategy="worker"
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <GoogleAdSense publisherId="pub-8210764995783356" />
            <Script
              id="google-analytics-script"
              strategy="worker"
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
              }}
            />
            <div className="page-container position-relative">
              <Header />
              <main>
                <InstantAppPreview>
                  <Component {...pageProps} />
                </InstantAppPreview>
              </main>

              <div className="card content rounded-m overflow-hidden mt-0">
                <AdUnit
                  publisherId="pub-8210764995783356"
                  slotId="2942772199"
                  layout="display"
                />
              </div>

              <Footer />
            </div>
            <SnackbarProvider />
            <Spinner />
          </Layout>
          <WorkboxNotifications />
          <GoogleAnalyticsReporter />
        </Hydrate>
      </QueryClientProvider>
    </div>
  )
}

export default MyApp
