import useGlobalStore from "../stores/global"

export default function Spinner({ size = 85 }) {
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <filter
          id="a"
          x="-100%"
          y="-100%"
          width="300%"
          height="300%"
          colorInterpolationFilters="sRGB"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation={2.4} />
          <feComponentTransfer result="cutoff">
            <feFuncA type="table" tableValues="0 0 0 0 0 0 1 1 1 1 1" />
          </feComponentTransfer>
        </filter>
      </defs>
      <g filter="url(#a)" transform="translate(25 25)">
        <g>
          <circle cx={17} r={5} fill={isDark ? "#5699d2" : "#a6dff2"}>
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.6;8.4;3.6"
              dur="4s"
              repeatCount="indefinite"
              begin="-0.25s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="4s"
            repeatCount="indefinite"
            begin="0s"
          />
        </g>
        <g>
          <circle cx={17} r={5} fill={isDark ? "#d8ebf9" : "#24a9e1"}>
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.56;8.3;3.56"
              dur="2s"
              repeatCount="indefinite"
              begin="-0.2s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="2s"
            repeatCount="indefinite"
            begin="-0.05s"
          />
        </g>
        <g>
          <circle cx={17} r={5} fill={isDark ? "#71c2cc" : "#42507b"}>
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.56;8.3;3.56"
              dur="1.33s"
              repeatCount="indefinite"
              begin="-0.15s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="1.33s"
            repeatCount="indefinite"
            begin="-0.1s"
          />
        </g>
        <g>
          <circle cx={17} r={5} fill={isDark ? "#5699d2" : "#a6dff2"}>
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.56;8.3;3.56"
              dur="1s"
              repeatCount="indefinite"
              begin="-0.1s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="1s"
            repeatCount="indefinite"
            begin="-0.15s"
          />
        </g>
        <g>
          <circle cx={17} r={5} fill={isDark ? "#d8ebf9" : "#24a9e1"}>
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.56;8.3;3.56"
              dur="0.8s"
              repeatCount="indefinite"
              begin="-0.05s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="0.8s"
            repeatCount="indefinite"
            begin="-0.2s"
          />
        </g>
      </g>
    </svg>
  )
}
