export default {
  dir: "ltr" as "rtl" | "ltr",
  language: "tr" as "en" | "ar" | "tr",
  emptyList: "Gösterilecek uygulama yok",
  og: {
    title:
      "Appollow - iPhone Uygulamalarında Ücretsiz Sınırlı Teklifler ve İndirimler",
    description:
      "En son iOS uygulaması ve oyun fırsatlarından haberdar olun. Favori uygulamalarınızda indirimler ve Sınırlı Süreli Ücretsiz Teklifler bulun. Yalnızca sınırlı bir süre için ücretsiz olarak sunulan en iyi uygulamaları ve oyunları keşfedin. Kendi favori uygulama listenizi oluşturun ve topluluğumuzla paylaşın!",
  },
  settings: {
    translateSubmitterNote: "Yükleyicinin yorumlarını çevir",
    translateSubmitterNoteSub: "Yükleyicinin yorumlarını otomatik çevir",
    appearance: "Görünüş",
    appearanceSub: "Koyu modu, Açık modu veya Otomatik",
    languagePicker: "Dil",
    languagePickerSub: "Uygulama dilini seçin",
    selectLanguage: "Dilinizi seçiniz",
    share: "Appollow'u Paylaşın",
    shareSub: "Sadece bir dokunuş! Gerisini biz hallederiz!",
    dark: "Koyu",
    light: "Açık",
    auto: "Otomatik",
    notSet: "Ayarlanmadı",
    appAnimation: "Animasyonlar",
    appAnimationSub:
      "Uygulama animasyonlarını etkinleştirip devre dışı bırakın",
    transparency: "Saydamlık",
    transparencySub: "Uygulamayı saydamlık ayarını değiştir",
    notifications: "Bildirimler",
    notificationsSub: "Uygulamanın bildirimlerini aç / kapat",
  },
  home: {
    discounted: "Indirimli",
    latestApps: "En Yeni Uygulamalar",
    latestGames: "En Yeni Oyunlar",
    latestAppsAndGames: "En Yeni Uygulamalar ve Oyunlar",
    viewAll: "Hepsini gör",
  },
  navBar: {
    apps: "Uygulamalar",
    games: "Oyunlar",
    settings: "Ayarlar",
    home: "Ana Sayfa",
    submit: "Yükle",
    search: "Arama",
    feed: "Gönderiler",
  },
  footer: {
    p: "Yalnızca ihtiyacınız olan şey!",
    bestPlatform:
      "En sevdiğiniz uygulamaları ve oyunları bulmanıza yardımcı olacak en iyi platform",
    copyright: `Telif hakkı © Appollow ${new Date().getFullYear()}. Tüm Hakları Saklıdır.`,
  },
  submit: {
    hero: "Burada, yalnızca uygulama adını arayarak veya id'sini  ekleyerek uygulamaları veya oyunları paylaşabilirsiniz, gerisini sizin için biz hallederiz 😃.",
    search: "Uygulama adını, id'sini veya bağlantısını yazın...",
    selectApp: "Uygulama Seç",
    selectedApp: "Seçilen Uygulama",
    price: "Eski Fiyat",
    priceSub: "Biliyorsanız, uygulamanın ESKİ fiyatını girin.",
    description: "Tanımlama *",
    descriptionSub:
      "Uygulama hakkında gerekli gördüğünüz tüm detayları ekleyebilirsiniz. Bu açıklama, gönderildiğinde uygulama ayrıntılarına eklenecektir. En az 30 karakter yazabilirsiniz",
    descriptionInput: "uygulama hakkında bir şeyler yazin...",
    submit: "Gönder",
    noResult: "Sonuç yok",
    noResultSub: "Aramanız sonuç getirmedi. Farklı kelimeleri deneyin.",
    success: "Başarıyla gönderildi",
    error: "Uygulama gönderilirken bir hata oluştu",
    submittingApp: "Uygulama gönderiliyor",
    isSimilar: "Bu uygulama için zaten benzer bir tanımlama var",
    loginError: "Uygulamaları paylaşmak için giriş yapmalısınız",
    descriptionTooShort: "Açıklama çok kısa",
  },
  search: {
    hero: "Burada bir uygulama adı veya uygulama işlevi için arama yapabilirsiniz ve size mevcut en iyi eşleşen sonuçları göstereceğiz.",
    search: "yaz...",
    noResult: "Sonuç yok",
    noResultSub: "Aramanız sonuç getirmedi. Farklı kelimeleri deneyin.",
  },
  apps: {
    hero: "Burada zamana göre sıralanmış en son eklenen uygulamaları bulacaksınız.",
    apps: "Uygulamalar",
    games: "Oyunlar",
    all: "Hepsi",
    latestApps: "En Yeni Uygulamalar",
    latestAppsGames: "En Yeni Uygulamalar ve Oyunlar",
    latestGames: "En Yeni Oyunlar",
  },
  previewApp: {
    free: "Ücretsiz",
    view: "Aç",
    removed: "Silinmiş",
    description: "Açıklama",
    showMore: "Daha fazla göster...",
    showLess: "Daha az göster",
    ageRating: "Yaş Sınırı",
    version: "Sürüm",
    size: "Boyut",
    ratingsReviews: "Oylar & Yorumlar",
    priceUpdated: "Fiyat Güncellendi",
    ratings: "Oylar",
    submitter: {
      submitterNote: "Paylaşımcının Notu",
      guest: "Konuk",
      date: {
        seconds: "saniye",
        minAgo: "1 dakika önce",
        minutes: "dakika",
        hourAgo: "1 saat önce",
        hours: "saat",
        yesterday: "Dün",
        days: "Gün",
        lastWeek: "Geçen hafta",
        weeks: "hafta",
        lastMonth: "Geçen ay",
        months: "Ay",
        lastYear: "Geçen yıl",
        years: "yıl",
        lastCentury: "Geçen yüzyıl",
        centuries: "yüzyıl",
        ago: "Önce",
        justNow: "Şu anda",
      },
      seeOriginal: "Orijinali Gör",
      original: "Orijinal",
      seeMore: "daha fazlasını gör",
    },
    screenshots: "Ekran görüntüleri",
    dealOver: "İndirim bitti",
    relatedApps: "İlgili Uygulamalar",
    monthlySubscriptions: "Uygulama İçi Abonelikler",
    hasInAppPurchases: "Uygulama İçi Satın Alımlar",
    totallyFree: "Tamamen Ücretsiz",
    noIAP: "Uygulama İçi Satın Alımlar Yok",
  },
  shareMenu: {
    shareApp: "Uygulamayı Paylaş",
    shareSub:
      "Bağlantıyı sosyal medyada paylaşın veya panoya kaydetmek için Kopyala'ya dokunun.",
    email: "E-posta",
    copy: "Kopyala",
    close: "Kapat",
    other: "Diğer",
  },
  sidebar: {
    guest: "Konuk",
    welcometoAppollow: "Appollow'a hoş geldiniz",
    menu: "Menü",
    welcome: "Hoş geldin",
    hot: "HOT",
    signup: "Üye ol",
    login: "Giriş yap",
    logout: "Çıkış Yap",
    settings: "Ayarlar",
    close: "Kapat",
    contact: "Bize Ulaşın",
    aboutUs: "Hakkımızda",
  },
  offline: {
    title: "Çevrimdışı!",
    body: "Görünüşe göre internete bağlı değilsiniz, bağlantınızı kontrol edin ve internete bağlı olduğunuzdan emin olun ve ardından uygulamayı tekrar açın.",
    goHome: "Ana Sayfa",
    contact: "Bize Ulaşın",
  },
  page404: {
    pageNotFound: "Sayfa Bulunamadı",
    pageNotFoundSub: "Üzgünüz, aradığınız sayfa bulunamadı.",
    goHome: "Ana Sayfa",
    contact: "Bize Ulaşın",
  },
  snackbar: {
    error: "Hata oluştu",
    priceUpdated: "Fiyat Güncellendi",
    updatingPrice: "Fiyat Güncelleniyor...",
    appUpdateError: "Uygulama bilgileri güncellenirken hata oluştu",
  },
  contact: {
    hero: "Herhangi bir sorunuz veya öneriniz varsa, lütfen aşağıdaki formu kullanarak bizimle iletişime geçin.",
    followUs: "Bizi takip edin",
    followUsSub:
      "Bizi takip etmek için aşağıdaki sosyal medya hesaplarımızı takip edebilirsiniz.",
    tapToEmail: "E-posta",
    name: "Ad",
    email: "E-posta",
    message: "Mesaj",
    sendMessage: "Mesaj Gönder",
    required: "zorunlu",
    messageSent: "Mesajınız gönderildi",
    thankYou:
      "Bizimle iletişime geçtiğiniz için teşekkür ederiz. En kısa sürede size geri dönüş yapacağız.",
    nameError: "Adınızı girin",
    emailError: "E-posta adresinizi girin",
    messageError: "Mesajınızı girin",
    captchaError: "Captcha'yla bit hata oldu",
  },
  profile: {
    appSubmissions: "Paylaşılan Uygulamalar",
    editProfile: "Profili Düzenle",
    noDescription: "Profiliniz için açıklama ekleyin",
    noApps: "Henüz herhangi bir uygulama eklenmedi",
    submitNow: "Favori uygulamalarınızı şimdi paylaşın",
  },
  editProfile: {
    update: "Güncelle",
  },
  pwa: {
    installed: "Appollow başarıyla yüklendi!",
    newVersion: "Yeni Sürüm Var!",
    newVersionDescription:
      "Bu web uygulamasının daha yeni bir sürümü mevcut, güncellemek için yeniden yüklensin mi?",
    update: "Güncelle",
    addToHomeScreen: "Appollow'u Ana Ekranınıza ekleyin",
    addToHomeScreenDescription:
      "Appollow'u ana ekranınıza kurun ve ona normal bir uygulama gibi erişin. Safari menünüzü açın ve 'Ana Ekrana Ekle'ye dokunun.",
    install: "Yükle",
    later: "Şimdi değil",
    done: "Tamam",
    moreInfo: "bilgi",
  },
  session: {
    welcome: "Hoşgeldiniz",
    toAppollow: "Appollow'ya",
    signedOut: "Oturumunuz kapatıldı!",
  },
  signup: {
    name: "İsim",
    email: "E-posta",
    chooseAPassword: "Bir şifre Şeçin",
    confirmYourPassword: "şifrenizi onaylayın",
    bioPlaceholder:
      "bize kendin hakkında bir şey söyle, bu senin Biyografin olacak...",
    required: "gereklidir",
    uploadPhoto: "Profil Fotoğrafı Yükle",
    createAccount: "Hesap Oluştur",
    signInWith: "veya doğrudan oturum açın",
    alreadyRegistered: "Zaten kayıtlı? Giriş yapın",
    fileSizeError: "Dosya boyutu çok büyük (maksimum 5 MB)",
    socialCheckbox: "Sosyal medya linklerimi ekle",
    optional: "opsiyonel",
    addPhoto: "fotoğraf ekle",
  },
  login: {
    password: "Şifre",
    login: "Giriş yap",
    forgot: "Şifremi Unuttum",
  },
  passwordReset: {
    recover: "Hesabınızı Kurtarın",
    description:
      "Sadece kaydettiğiniz e-posta adresinizi girin ve şifre sıfırlama talimatlarını size göndereceğiz.",
    send: "Sıfırlama Talimatlarını Gönder",
    emailSent: "E-posta Başarıyla Gönderildi",
  },
  article: {
    share: "Bu makaleyi paylaş:",
  },
  filters: {
    filters: "Filtreler",
    price: "Fiyat",
    all: "Hepsi",
    free: "Ücretsiz",
    discounted: "Indirimli",
    paid: "Ücretli",
    accept: "Kabul et",
    reset: "Sıfırla",
  },
  deleteComment: {
    areYouSure: "Emin misin?",
    commentWillBeDeleted: "Bu yorum kalıcı olarak silinecek",
    yes: "Evet",
    cancel: "Iptal",
  },
  share: {
    freeForLimitedTime: "Sınırlı süre için indirimli",
    oldPrice: "Eski fiyat:",
  },
  about: {
    subheader: "iPhone ve iPad için en iyi uygulama platformu",
    description:
      "Appollow, iPhone'lar ve iPad'ler gibi Apple mobil cihazlarına özgü uygulamalar ve oyunlar için bir topluluktur. Kullanıcıların önemsediği uygulamaları sunmayı ve uygulamaları aramayı sezgisel ve kolay hale getirmeyi ve iOS için indirimler ve sınırlı süreli ücretsiz uygulama ve oyunlar teklif etmeyi hedefliyoruz.",
    heading3: "Appollow kalite için tasarlanmıştır",
    heading3sub:
      "Appollow, en son web teknolojileri ile oluşturulmuştur ve iOS cihazlarda parlak bir geleceğe sahip olacak progresif web uygulamalarının (PWA) yolunu açmaktadır.",
    about1: "Modern Tasarım \n Uygulaması",
    about2: "Üstün \n Kalite",
    about3: "Günlük Yeni \n İçerik",
    about4: "En İyi Web \n Teknolojileri",
    features: "Özellikler",
    featuresSub:
      "Appollow, faydaya odaklanarak uygulamaları aramayı kolaylaştırmak için tasarlanmıştır.",
    feature1: "Uygulamaları keşfedin ve arayın",
    feature1sub:
      "Sınırlı süreli ücretsiz uygulamaları ve oyunları keşfedin ve uygulamaları kolayca arayın.",
    feature2: "Güzel Modern Tasarım",
    feature2sub:
      "Appollow, PWA teknolojisi ile oluşturulmuştur ve onu ana ekranınıza yükleyebilir ve hiçbir şey indirmenize gerek kalmadan normal bir uygulama gibi kullanabilirsiniz.",
    feature3: "Uygulamalarınızı Yayınlayın",
    feature3sub:
      "Kullanıcılar, kullandıkları ve sevdikleri uygulamaları gönderebilir ve paylaşabilir. Bir profil oluşturun ve favori uygulamalarınızı sergileyin.",
    builtBy: "Appollow'nın geliştirici:",
    sponsoredBy: ", Sponsor:",
  },
  notifications: {
    title: "Bildirimleri Etkinleştir",
    enable: "etkinleştir",
    description:
      "En yeni uygulamaları ve oyunları kaçırmamak için bildirimleri etkinleştirin!\n (iOS 16.4 veya üstü gereklidir)",
    enableDenied: "Lütfen cihaz ayarlarınızdan bildirimleri etkinleştirin",
    success: "Bildirimler etkin",
    successMsg: "Artık Appollow'dan bildirim alabileceksiniz",
    limitedTimeFree: "Sınırlı süre ücretsiz!",
    installPWAFirst: "Lütfen önce Appollow'u ana ekranınıza yükleyin.",
    new: "Yeni!",
    updateTo164:
      "Lütfen önce iOS cihazınızı iOS 16.4 veya sonraki bir sürüme güncelleyin",
    prev: "Önce",
  },
  feed: {
    feed: "Gönderiler",
    write: "bir uygulama hakkında yaz...",
    share: "Paylaş",
  },
}
