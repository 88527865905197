import { memo, useEffect, useState } from "react"
import { FaBars, FaSun, FaMoon, FaArrowLeft } from "react-icons/fa"
import { NavLink } from "./NavLink"
import NoSsr from "./NoSsr"
import useGlobalStore from "../stores/global"
import useUnsavedStore from "../stores/unsaved"
import useRouterStore from "stores/routerStore"

const Header = () => {
  const asPath = useRouterStore((state) => state.router?.asPath)
  const isDark = useGlobalStore((state) => state.isDark)
  const setAppearance = useGlobalStore((state) => state.setAppearance)
  const transparency = useGlobalStore((state) => state.transparency)
  const toggleSidebar = useUnsavedStore((state) => state.toggleSidebar)
  const appId = useRouterStore((state) => state.router?.query.appId)
  const back = useRouterStore((state) => state.router?.back)

  const showBars =
    asPath === "/" || asPath === "/settings" || asPath === "/submit"

  const [Pos, setPos] = useState("")
  useEffect(() => {
    const activateHeader = () => {
      const scrolled = document.scrollingElement!.scrollTop
      if (scrolled >= 30) {
        setPos("header-active")
      } else {
        setPos("")
      }
    }
    document.addEventListener("scroll", activateHeader)
    return () => document.removeEventListener("scroll", activateHeader)
  }, [])
  return (
    <NoSsr>
      <div
        className={`header header-fixed header-auto-show header-logo-app ${
          !appId && Pos
        } ${transparency ? "transparency" : ""}`}
      >
        <NavLink href="/" className="header-title">
          APPOLLOW
        </NavLink>
        <a
          onClick={() => (showBars ? toggleSidebar() : back?.())}
          data-menu="menu-main"
          className="header-icon header-icon-1"
        >
          {showBars ? <FaBars /> : <FaArrowLeft />}
        </a>
        <a
          onClick={() => setAppearance(isDark ? "Light" : "Dark")}
          className="header-icon header-icon-2"
        >
          {isDark ? <FaMoon /> : <FaSun />}
        </a>
      </div>
    </NoSsr>
  )
}
export default memo(Header)
