import { ReactElement, useEffect } from "react"
import dynamic from "next/dynamic"
import ThemeColor from "../components/ThemeColor"
import useMediaQuery from "../utils/useMediaQuery"
import TopBar from "../components/TopBar"
import useGlobalStore from "../stores/global"
import useRouterStore from "stores/routerStore"
import InstallAppModals from "components/InstallAppModals"

const NavBar = dynamic(() => import("../components/NavBar"))
const SideBar = dynamic(() => import("../components/sidebar"))
const DesktopSideBar = dynamic(() => import("../components/DesktopSideBar"))
const SideNavbar = dynamic(() => import("../components/SideNavbar"))

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Layout = ({ children }: Props): ReactElement => {
  const appearance = useGlobalStore((state) => state.appearance)
  const appVisits = useGlobalStore((state) => state.appVisits)
  const language = useGlobalStore((state) => state.language)
  const locale = useRouterStore((state) => state.router?.locale)
  const pathname = useRouterStore((state) => state.router?.pathname)
  const query = useRouterStore((state) => state.router?.query)
  const asPath = useRouterStore((state) => state.router?.asPath)
  const push = useRouterStore((state) => state.router?.push)
  const setAnimation = useGlobalStore((state) => state.setAnimation)
  const setDark = useGlobalStore((state) => state.setDark)
  const incrementAppVisits = useGlobalStore((state) => state.incrementAppVisits)

  const isDesktop = useMediaQuery("(min-width: 1200px)")

  const setToDark = (body: DOMTokenList) => {
    body?.remove("theme-light")
    body?.add("theme-dark")
    setDark(true)
  }
  const setToLight = (body: DOMTokenList) => {
    body?.remove("theme-dark")
    body?.add("theme-light")
    setDark(false)
  }

  useEffect(() => {
    const body = document.querySelector("body")?.classList
    if (appearance === "Dark") {
      setToDark(body!)
    } else if (appearance === "Light") {
      setToLight(body!)
    } else {
      const isDarkMode = window.matchMedia(
        "(prefers-color-scheme: dark)",
      ).matches
      const isLightMode = window.matchMedia(
        "(prefers-color-scheme: light)",
      ).matches
      if (isDarkMode) {
        setToDark(body!)
      } else if (isLightMode) {
        setToLight(body!)
      } else {
        setToLight(body!)
      }
    }
  }, [appearance])

  useEffect(() => {
    // check if device is android and disable animations for it in the first visit
    const isAndroid = () => {
      const ua = navigator.userAgent.toLowerCase()
      return ua.indexOf("android") > -1
    }
    if (appVisits === 0 && isAndroid()) {
      setAnimation(false)
    }
    incrementAppVisits()
  }, [])

  useEffect(() => {
    // set the local language as default if it exists
    if (language && locale && language !== locale) {
      push?.({ pathname, query }, asPath, { locale: language })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, locale, push])

  if (isDesktop)
    return (
      <div className="d-grid" style={{ gridTemplateColumns: "20% 60% 20%" }}>
        <div
          className="d-flex justify-content-center pt-5"
          style={{ maxHeight: "100vh" }}
        >
          <SideNavbar />
        </div>
        <div className="pt-2">
          <ThemeColor />
          {children}
        </div>
        <DesktopSideBar />
      </div>
    )

  return (
    <>
      <ThemeColor />
      <TopBar />
      <SideBar />
      {children}
      <NavBar />
      <InstallAppModals />
    </>
  )
}
export default Layout
