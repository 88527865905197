import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { iconVariants } from "../components/snackbars/IconVariants"

type NotificationType = {
  isActive?: boolean
  text?: string
  color?: keyof typeof iconVariants
}

interface State {
  snackbar: NotificationType
  toast: NotificationType
  toggleSnackbar: (payload: NotificationType) => void
  toggleToast: (payload: NotificationType) => void
}

const useSnackbarStore = create<State>()(
  devtools(
    (set) => ({
      snackbar: {
        isActive: false,
        text: "",
        color: "blue",
      },
      toast: {
        isActive: false,
        text: "",
        color: "blue",
      },
      toggleSnackbar: (payload: NotificationType) =>
        set((state) => ({
          ...state,
          snackbar: {
            ...state.snackbar,
            ...payload,
            isActive:
              // check if isActive is passed
              typeof payload.isActive === "boolean" ? payload.isActive : true,
          },
        })),
      toggleToast: (payload: NotificationType) =>
        set((state) => ({
          ...state,
          toast: {
            ...state.toast,
            ...payload,
            isActive:
              // check if isActive is passed
              typeof payload.isActive === "boolean" ? payload.isActive : true,
          },
        })),
    }),
    { name: "snackbar-store" },
  ),
)

export default useSnackbarStore
