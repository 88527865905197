import { memo, useEffect, useState } from "react"
import Image from "next/image"
import WaveHeader from "./WaveHeader"
import HeaderCard from "./HeaderCard"
import useMediaQuery from "utils/useMediaQuery"
import MenuIcon from "./MenuIcon"
import AppollowLogo from "../AppollowLogo"
import useUnsavedStore from "stores/unsaved"
import useGlobalStore from "stores/global"
import useSessionStore from "stores/session"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"

const Header = () => {
  const appId = useRouterStore((state) => state.router?.query.appId)
  const pathname = useRouterStore((state) => state.router?.pathname)
  const toggleSidebar = useUnsavedStore((state) => state.toggleSidebar)
  const photoURL = useSessionStore((state) => state.user.photoURL)
  const uid = useSessionStore((state) => state.user.uid)
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const isMobile = useMediaQuery("(max-width: 1200px)")
  const isDark = useGlobalStore((state) => state.isDark)

  const pageTitle = (s = "home") => {
    switch (s) {
      case "/apps":
        return "Apps"
      case "/search":
        return "Search"
      case "/":
        return "Home"
      case "/submit":
        return "Submit"
      case "/settings":
        return "Settings"

      default:
        return ""
    }
  }
  const [Pos, setPos] = useState(false)
  useEffect(() => {
    const activateHeader = () => {
      const scrolled = document.scrollingElement!.scrollTop
      if (scrolled >= 30) {
        setPos(true)
      } else {
        setPos(false)
      }
    }
    document.addEventListener("scroll", activateHeader)
    return () => document.removeEventListener("scroll", activateHeader)
  }, [])
  return (
    <div style={{ height: "calc(90px + (env(safe-area-inset-top)))" }}>
      <div
        className="top-bar-shadow"
        style={{ opacity: !appId || !isMobile ? 0 : 1 }}
      />
      <div
        className={`page-title page-title-small d-flex align-items-center justify-content-between position-fixed ${
          Pos && !appId ? "opacity-0" : ""
        }`}
        style={{ width: "calc(100% - 40px)", zIndex: "99" }}
      >
        <h2 className="greeting-text">
          <MenuIcon />
          {!isMobile && pageTitle(pathname)}
        </h2>
        {isMobile && (
          <>
            <AppollowLogo fill="#fff" width="230px" />
            <Image
              key={uid + photoURL}
              onClick={toggleSidebar}
              height={40}
              width={40}
              className="bg-fade-gray1-dark preload-img img-profile"
              src={photoURL ? photoURL : "/images/avatars/4s.png"}
              alt="profile photo"
              priority
              placeholder="blur"
              blurDataURL={imagePlaceholder(isDark)}
              unoptimized
            />
          </>
        )}
      </div>
      {animationToggle ? <WaveHeader /> : <HeaderCard />}
    </div>
  )
}

export default memo(Header)
