import { NextRouter } from "next/router"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface State {
  router?: NextRouter
  setRouter: (router: NextRouter) => void
}

const useRouterStore = create<State>()(
  devtools(
    (set) => ({
      router: undefined,
      setRouter: (router: NextRouter) => set((state) => ({ ...state, router })),
    }),
    {
      name: "router-store",
    },
  ),
)

export default useRouterStore
