import Link from "next/link"
import useRouterStore from "stores/routerStore"

interface Props {
  href: string
  exact?: boolean
  children: React.ReactNode
  className?: string
  id?: string
  as?: string
  title?: string
}
function NavLink({ href, exact, children, className, id, as, title }: Props) {
  const page = useRouterStore((state) => state.router?.query.page)
  const asPath = useRouterStore((state) => state.router?.asPath)
  const pathname = useRouterStore((state) => state.router?.pathname)

  const isActive = exact
    ? pathname === href || page === title || asPath === as
    : asPath?.startsWith(href) || page === title

  if (isActive) {
    className += " active"
  }

  return (
    <Link href={href} as={as} id={id} className={className}>
      {children}
    </Link>
  )
}

export { NavLink }
