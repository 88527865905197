import { iconVariants } from "./IconVariants"

type Props = {
  active?: boolean
  text?: string
  color?: keyof typeof iconVariants
}

const Snackbar = ({ text, color, active }: Props) => {
  return (
    <div
      className={`snackbar-toast bg-${color}-dark fade ${active ? "show" : ""}`}
      dir="auto"
    >
      <i className={iconVariants[color ?? "blue"]}></i>
      <span className="mx-2 text-white">{text}</span>
    </div>
  )
}

export default Snackbar
