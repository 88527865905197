import { useStickyTop } from "utils/useStickyTop"
import useMediaQuery from "utils/useMediaQuery"
import useGlobalStore from "stores/global"
import NoSsr from "components/NoSsr"

const HeaderCard = () => {
  const isDark = useGlobalStore((state) => state.isDark)
  const isSticky = useStickyTop()
  const isMobile = useMediaQuery("(max-width: 1200px)")
  return (
    <NoSsr>
      <div
        className={isSticky ? "waves-position" : "waves-position fixed-to-top"}
        style={{
          width: isMobile ? "100%" : isSticky ? "100%" : "60%",
        }}
      >
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 70"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v84h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              className="bg-highlight"
              fill={
                isDark ? "hsla(214, 56%, 32%, 0.8)" : "rgba(82, 143, 221,0.7)"
              }
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill={
                isDark ? "hsla(214, 56%, 32%, 0.6)" : "rgba(82, 143, 221,0.5)"
              }
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="6"
              fill={
                isDark ? "hsla(214, 56%, 32%, 0.4)" : "rgba(82, 143, 221,0.3)"
              }
            />
            {/* <use
              xlinkHref="#gentle-wave"
              x="48"
              y="7"
              fill={isDark ? "rgb(35, 65, 104)" : "rgb(82, 143, 221)"}
            /> */}
          </g>
        </svg>
      </div>
    </NoSsr>
  )
}

export default HeaderCard
