const AppollowSquareLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    className="mx-auto mt-4 rounded-m bg-white border"
    viewBox="0 0 1000 1000"
  >
    <path
      fill="#253974"
      fillRule="evenodd"
      d="M500.8,625.2L350.875,780.98s-23.568,25.935,15.283,52.715c2.055,1.416,134.642,64.516,134.642,64.516l128.819-61.369s44.869-26.041,21.834-55.075C646.131,775.059,500.8,625.2,500.8,625.2Zm0,96.775-94.458,76.189s-18.945,15.519,9.306,31.6c1.354,0.77,84.83,42.008,84.83,42.008l84.746-42.008s30.815-14.273,9.848-31.106C590.951,795.347,500.8,721.972,500.8,721.972Z"
    />
    <path
      fill="#24a9e1"
      fillRule="evenodd"
      d="M502.061,456.039L306.339,714.1s-14.563,31.111,25.074,66.948c0.06,0.055-.023-5.461,2.85-9.775S501.3,596.087,501.3,596.087L666.84,768.237a15.261,15.261,0,0,1,3.324,10.753c0,0.242,37.1-26.69,24.853-61.739C693.352,714.962,502.061,456.039,502.061,456.039Z"
    />
    <path
      fill="#a6dff2"
      fillRule="evenodd"
      d="M500.426,101.2L241.91,605.528s-23.965,51.673,41.7,109.363c0.1,0.088-.037-8.791,4.721-15.736S502,415.127,502,415.127L709.945,696.008s5.542,7.724,5.508,17.309c0,0.389,57.16-50.235,43.277-107.789C758.444,604.343,500.426,101.2,500.426,101.2Z"
    />
  </svg>
)
export default AppollowSquareLogo
