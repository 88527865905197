import { useMutation, useQuery } from "@tanstack/react-query"
import { getAuth } from "firebase/auth"
import createFirebaseApp from "firebase/firebase"
import { fetchProfile, updateUserMutation } from "queries"
import { memo, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import useSessionStore from "stores/session"
import useSnackbarStore from "stores/snackbar"

const app = createFirebaseApp()
const auth = getAuth(app)

const Auth = () => {
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const login = useSessionStore((state) => state.login)
  const logout = useSessionStore((state) => state.logout)
  const userId = useSessionStore((state) => state.user.uid)
  const LocalAccessToken = useSessionStore((state) => state.user?.accessToken)
  const LocalDisplayName = useSessionStore((state) => state.user?.displayName)
  const [currentUser, loading] = useAuthState(auth)

  const { data: profileData } = useQuery({
    enabled: !!userId,
    queryKey: ["profile", userId],
    queryFn: () => fetchProfile(userId),
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  })

  const { mutateAsync } = useMutation({
    mutationFn: updateUserMutation,
    onError(error) {
      console.log(error)
      toggleToast({
        color: "yellow",
        text: "user data update failed",
      })
    },
  })

  useEffect(() => {
    const checkUser = async () => {
      if (loading) return

      currentUser ? login({ ...currentUser, ...profileData }) : logout()

      if (!currentUser && !LocalDisplayName) return

      const userToken = await currentUser?.getIdToken()

      // save user data in mongodb when first signup or if it doesn't exist in firestore yet
      if (userToken && LocalAccessToken && currentUser) {
        mutateAsync({
          accessToken: userToken,
          uid: currentUser.uid,
          email: currentUser?.email,
        })
      }
      if (
        (currentUser &&
          profileData &&
          (!profileData?.photoURL ||
            !profileData?.email ||
            !profileData?.displayName)) ||
        profileData === null
      ) {
        mutateAsync({
          accessToken: userToken,
          uid: currentUser?.uid || LocalAccessToken,
          email: currentUser?.email,
          displayName: currentUser?.displayName,
          photoURL: currentUser?.photoURL,
        })
      }
    }
    checkUser().catch(console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser,
    currentUser?.uid,
    currentUser?.displayName,
    LocalDisplayName,
    profileData,
  ])

  return null
}

export default memo(Auth)
