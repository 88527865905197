import React, { memo, useEffect } from "react"
import Toast from "./Toast"
import Snackbar from "./Snackbar"
import useSnackbarStore from "../../stores/snackbar"

const SnackbarProvider = () => {
  const toggleSnackbar = useSnackbarStore((state) => state.toggleSnackbar)
  const isSnackbarActive = useSnackbarStore((state) => state.snackbar?.isActive)
  const snackbarText = useSnackbarStore((state) => state.snackbar?.text)
  const snackbarColor = useSnackbarStore((state) => state.snackbar?.color)

  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const isToastActive = useSnackbarStore((state) => state.toast?.isActive)
  const toastText = useSnackbarStore((state) => state.toast?.text)
  const toastColor = useSnackbarStore((state) => state.toast?.color)

  useEffect(() => {
    const timer = setTimeout(
      () => {
        isSnackbarActive &&
          toggleSnackbar({
            isActive: false,
          })
        isToastActive &&
          toggleToast({
            isActive: false,
          })
      },
      snackbarColor === "loading" || toastColor === "loading" ? 5000 : 3000,
    )
    return () => clearTimeout(timer)
  }, [
    isSnackbarActive,
    isToastActive,
    snackbarColor,
    toastColor,
    toggleSnackbar,
    toggleToast,
  ])

  return (
    <>
      <Toast active={isToastActive} text={toastText} color={toastColor} />
      <Snackbar
        active={isSnackbarActive}
        text={snackbarText}
        color={snackbarColor}
      />
    </>
  )
}

export default memo(SnackbarProvider)
